$background-color-active: #f6f6f6;
$background-color-inactive: #f6f6f68f;
$text-color: #143f71;
$opacity-hover: 0.9;
$opacity-disabled: 0.5;

.controlPanel {
    position: fixed;
    bottom: 10px;
    right: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    font-size: 14px;
    font-weight: 600;
    color: white;
    text-transform: uppercase;

    & .switch {
      background-color: $background-color-active;
    }

    & .active {
      background-color: $background-color-active;
    }

    & .inactive {
      background-color: $background-color-inactive;
    }

    & .controls {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      padding: 10px;
      margin-top: 10px;
      background-color: #0000005f;
      max-height: 50vh;
      max-width: 100vw;
      overflow: hidden;
      overflow-y: auto;
      overflow-x: auto;
    }

    & .buttonGroup {
      width: fit-content;
      display: flex;
      align-items: flex-end;
    }

    & .container {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
      margin: 10px 0px 10px 10px;
      background-color: #0000005f;
    }

    & button {
      outline: none;
      border: none;
      cursor: pointer;
      font-size: 14px;
      font-weight: 600;
      color: $text-color;
      text-transform: uppercase;
      width: 144px;
      height: 24px;
      border-radius: 10px;
      margin-left: 10px;

      &:hover {
        opacity: $opacity-hover;
      }
      &:disabled {
        opacity: $opacity-disabled;
        cursor: not-allowed;
      }
    }

    @media screen and (max-width: 900px){
      bottom: 0px;
      right: 0px;

      & .switch {
        margin-right: 10px;
      }
    }

    @media screen and (max-height: 440px), screen and (max-width: 900px){
      & button {
        width: 90px;
        height: 20px;
        font-size: 10px;
        border-radius: 5px;
      }
    }
}